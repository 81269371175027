import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import {
    borderColorValue,
    borderWidthValue,
    getCSSVariable,
} from '../../constants/cssRules'

const StyledTableItem = styled.article`
    ${props => {
        const spacing = getCSSVariable('spacingMedium')
        return `
        width: 100%;
        padding-bottom: ${spacing};
        border-bottom: ${borderWidthValue(props)} ${
            props.noLine ? 'transparent' : borderColorValue(props)
        } solid;
        margin-bottom: ${props.noLine ? 'unset' : spacing};
`
    }}
`

const TableItem = ({ className, id, children, noLine, style }) => {
    const themeContext = useContext(ThemeContext)
    return (
        <StyledTableItem
            className={className}
            style={style}
            id={id}
            themeContext={themeContext}
            noLine={noLine}
        >
            {children}
        </StyledTableItem>
    )
}

export default TableItem
