import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { FormItemTypes, ThemeVariants } from '../../constants'
import {
    backgroundRule,
    fontSizeRule,
    colorRule,
    borderRadiusRule,
    borderRule,
    borderValue,
    variantBackgroundValue,
} from '../../constants/cssRules'

const StyledInput = styled.input`
    ${props => `
    ${backgroundRule(props)}
    ${fontSizeRule('14px')}
    ${colorRule(props)}
    ${borderRadiusRule(props)}
    ${borderRule(props, false, props.hasError)}
    &:hover {
        ${props.disabled ? '' : borderRule(props, true, props.hasError)}
    }
    &:focus {
        outline: ${props.disabled ? '' : borderValue(props, true)};
    }
    
    ${
        props.type === FormItemTypes.COLOR
            ? `
            position: relative;
            &:after {
                content: '';
                top: -10%;
                left: -10%;
                position: absolute;
                width: 120%;
                height: 120%;
                background-color: ${
                    props.disabled
                        ? variantBackgroundValue({
                              ...props,
                              variant: ThemeVariants.GRAY,
                          })
                        : props.value || 'white'
                };
                ${props.disabled ? 'cursor: not-allowed;' : ''}
            }
    `
            : ''
    }
            `}
`

const Input = props => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <StyledInput
            {...props}
            themeContext={themeContext}
            className={props.className || ''}
        />
    )
}

export default Input
