import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { labelColorValue } from '../constants/cssRules'

const StyledLabel = styled.label(props => ({
    color: labelColorValue(props),
    fontSize: 14,
    fontWeight: 700,
    transition: '0.2s color',
    textOverflow: 'ellipsis',
    display: 'inline-block',
}))

const Label = props => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <StyledLabel
            {...props}
            themeContext={themeContext}
            className={`rds-label rds-nowrap rds-overflow_hidden ${
                props.className || ''
            }`}
        >
            {props.children}
        </StyledLabel>
    )
}

export default Label
