import React from 'react'
import Typography from '../Typography'
import Card from '../Card'
import Icon from '../Icon'
import { TypographyTypes, ThemeVariants, Icons } from '../../constants'

const ComponentError = ({ componentName, errorMessage }) => {
    return (
        <Card className='rds-m_top__sm' variant={ThemeVariants.WARNING}>
            <div className='rds-flexbox align-center'>
                <Icon
                    type={Icons.WARNING}
                    variant={ThemeVariants.WARNING}
                    size={15}
                    className='rds-m_right__sm'
                />
                <Typography>{componentName} component error</Typography>
            </div>
            <Typography type={TypographyTypes.H4}>{errorMessage}</Typography>
        </Card>
    )
}

export default ComponentError
