import React from 'react'
import Template from '../Layout/Template'
import {
    Card,
    Header,
    HeaderSizes,
    ThemeVariants,
    Button,
    Typography,
    TypographyTypes,
} from 'rds'

const CardTemplate = () => {
    return (
        <Template
            hashValue='cards'
            componentName='Card'
            overview={
                <div>
                    <section className='rds-grid grid_2-columns'>
                        <Card
                            headerTitle='This is a simple Card'
                            headerText='You can put any type of content here'
                            headerSize={HeaderSizes.SMALL}
                        />
                    </section>
                    <section className='rds-m_top__md'>
                        <Header
                            title='Variants'
                            text='If you want the inner text to have the variant too, you also need to pass the ThemeVariant to that component'
                            size={HeaderSizes.SMALL}
                        />
                        <div className='rds-m_top__sm rds-grid rds-grid_3-columns'>
                            <Card
                                headerTitle='Default'
                                headerText='This is the simplest one, it will perfectly adapt to any color scheme and mode'
                                headerSize={HeaderSizes.SMALL}
                            />
                            <Card
                                variant={ThemeVariants.MAIN}
                                headerTitle='Main'
                                headerText='This will have the main color as background, so it will depend on which color you chose'
                                headerSize={HeaderSizes.SMALL}
                                headerVariant={ThemeVariants.WHITE}
                            />
                            <Card
                                variant={ThemeVariants.MAIN_LIGHT}
                                headerTitle='Main Light'
                                headerText='Uses a downgraded version of the main color. Be aware that this color is absolutely tied to your chosen pallette, so be careful when placing text on top of it'
                                headerSize={HeaderSizes.SMALL}
                            />
                            <Card
                                variant={ThemeVariants.SECONDARY}
                                headerTitle='Secondary'
                                headerText='This will have the secondary color as background, so it will depend on which color you chose'
                                headerSize={HeaderSizes.SMALL}
                            />
                            <Card
                                variant={ThemeVariants.SECONDARY_LIGHT}
                                headerTitle='Secondary Light'
                                headerText='This one is pretty cool as it gives a glassy look at the Card'
                                headerSize={HeaderSizes.SMALL}
                            />
                            <Card
                                variant={ThemeVariants.ERROR}
                                headerTitle='Error'
                                headerText='Of course this is useful for showing error messages. As shown, sometimes it might be helpful to add the Warning icon to it'
                                headerSize={HeaderSizes.SMALL}
                                headerIcon='Alarm'
                                headerVariant={ThemeVariants.ERROR}
                            />
                            <Card
                                variant={ThemeVariants.DARK}
                                headerTitle='Dark'
                                headerText='This one has a high contrast look, which will be helpful for showing important things'
                                headerSize={HeaderSizes.SMALL}
                                headerVariant={ThemeVariants.WHITE}
                            />
                            <Card
                                headerTitle='Glossy'
                                headerText='This variant is one of the coolest, but be careful with the contrast'
                                headerSize={HeaderSizes.SMALL}
                                variant={ThemeVariants.GLOSSY}
                            />
                            <Card
                                headerTitle='Dashed'
                                headerText='Useful for representing missing data or something that is not available'
                                headerSize={HeaderSizes.SMALL}
                                variant={ThemeVariants.DASHED}
                            />
                        </div>
                    </section>
                    <section className='rds-m_top__md'>
                        <Header title='Action' size={HeaderSizes.SMALL} />
                        <div className='rds-m_top__sm grid grid_3-columns'>
                            <Card onClick={() => alert('Hi there!')}>
                                <Header
                                    title='Click me!'
                                    text='This will trigger a callback'
                                    size={HeaderSizes.SMALL}
                                />
                            </Card>
                            <Card
                                className='rds-m_top__sm'
                                onDoubleClick={() => alert("What's up?")}
                            >
                                <Header
                                    title='Double-click me!'
                                    text='This will trigger a callback'
                                    size={HeaderSizes.SMALL}
                                />
                            </Card>
                            <Card className='rds-m_top__sm rds-flexbox align-top justify-between'>
                                <Header
                                    title='Action button'
                                    text='Use action buttons to make the user interact'
                                    size={HeaderSizes.SMALL}
                                />
                                <Button label='My action' cardHoverOnly />
                            </Card>
                        </div>
                    </section>
                    <section className='rds-m_top__md'>
                        <Header
                            title='Headers'
                            text='This allows you to create Cards containing Headers without the need of importing that component too'
                            size={HeaderSizes.SMALL}
                        />
                        <div className='rds-m_top__sm rds-grid rds-grid_3-columns'>
                            <Card
                                headerTitle={"A Card's title"}
                                headerText='Some text here'
                                headerSize={HeaderSizes.SMALL}
                            >
                                <div>
                                    <Typography type={TypographyTypes.P}>
                                        Some content here
                                    </Typography>
                                </div>
                            </Card>
                        </div>
                    </section>
                </div>
            }
            example={`
<Card
    variant={ThemeVariants.<VARIANT>}
    onClick={Function}
    onDoubleClick={Function}
    onMouseOver={Function}
    onMouseLeave={Function}
    style={Object}
    className={String}
    noBorderRadius={Boolean}
    align={String}
    id={String}
    headerTitle={String}
    headerText={String}
    headerSize={HeaderSizes.<SIZE>}
    headerVariant={ThemeVariants.<VARIANT>}
    headerIcon={IconTypes.<ICON>}
>
    // Some content here...
    // <Button label='Hello' cardHoverOnly ... />
</Card>`}
            properties={[
                {
                    name: 'variant',
                    type: 'ThemeVariants',
                    description: "import { ThemeVariants } from 'rds'",
                },
                {
                    name: 'onClick',
                    type: 'FUNCTION',
                    description: 'Callback to execute when the Card is clicked',
                },
                {
                    name: 'onDoubleClick',
                    type: 'FUNCTION',
                    description:
                        'Callback to execute when the Card is double-clicked',
                },
                {
                    name: 'onMouseOver',
                    type: 'FUNCTION',
                    description: 'Callback to execute when the Card is hovered',
                },
                {
                    name: 'onMouseLeave',
                    type: 'FUNCTION',
                    description: 'Callback to execute when the Card is left',
                },
                {
                    name: 'style',
                    type: 'OBJECT',
                    description: 'CSS object containing styles customizations',
                },
                {
                    name: 'className',
                    type: 'STRING',
                    description: 'CSS class/es you want to add',
                },
                {
                    name: 'headerTitle',
                    type: 'STRING',
                    description: "Title for Card's header",
                },
                {
                    name: 'headerText',
                    type: 'STRING',
                    description: "Text for Card's header",
                },
                {
                    name: 'headerSize',
                    type: 'HeaderSizes',
                    description: "Size for Card's header",
                },
                {
                    name: 'headerVariant',
                    type: 'ThemeVariants',
                    description: "Variant for Card's header",
                },
                {
                    name: 'headerIcon',
                    type: 'IconTypes',
                    description: "Icon for Card's header",
                },
            ]}
        />
    )
}

export default CardTemplate
