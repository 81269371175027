import React from 'react'
import {
    Header,
    CodeBlock,
    HeaderSizes,
    Card,
    ThemeVariants,
    Typography,
    TypographyTypes,
} from 'rds'

const GettingStarted = () => {
    return (
        <section id='getting-started'>
            <Header
                title='Getting started'
                text='Follow this simple guide to use the package'
            />
            <div className='rds-m_top'>
                <Header
                    title='Install'
                    text='First of all, add our package to your npm project'
                    size={HeaderSizes.MEDIUM}
                />
                <CodeBlock className='rds-m_top__sm'>
                    npm install rds --save
                </CodeBlock>
                <Typography className='rds-m_top__sm' type={TypographyTypes.P}>
                    or
                </Typography>
                <CodeBlock className='rds-m_top__sm'>yarn add rds</CodeBlock>
            </div>
            <div className='rds-m_top'>
                <Header
                    title='Theme'
                    text='A theme context needs to be provided to every component'
                    size={HeaderSizes.MEDIUM}
                />
                <div className='rds-m_top__md'>
                    <Header
                        title='1. Choose your pallete and scheme'
                        text='You can choose whatever hex color you want'
                        size={HeaderSizes.SMALL}
                    />
                    <CodeBlock className='rds-m_top__sm'>
                        {`const myTheme = {
    main: '#159DE0',
    secondary: '#35E4D7',
    scheme: ThemesNames.LIGHT,
    borderRadius: 12,
    borderWidth: 1,
}`}
                    </CodeBlock>
                </div>
                <div className='rds-m_top__md'>
                    <Header
                        title='2. Give context'
                        text={
                            "Use styled-component's ThemeProvider to give theme context to our components"
                        }
                        size={HeaderSizes.SMALL}
                    />
                    <CodeBlock className='rds-m_top__sm'>
                        {`import { ThemeProvider, ThemesNames } from 'rds'`}
                    </CodeBlock>
                    <CodeBlock className='rds-m_top__sm'>
                        {`<ThemeProvider theme={myTheme}>\n     // RDS components here...\n</ThemeProvider>`}
                    </CodeBlock>
                    <Card
                        variant={ThemeVariants.WARNING}
                        className='rds-m_top__sm'
                        headerTitle='Make sure to wrap every RDS component inside the ThemeProvider'
                        headerSize={HeaderSizes.XSMALL}
                    />
                </div>
                <div className='rds-m_top__md'>
                    <Header
                        title='4. Import styles'
                        text="It's crucial to import the main CSS file in order to successfully display all components and make use of the out-of-the-box CSS classes"
                        size={HeaderSizes.SMALL}
                    />
                    <CodeBlock className='rds-m_top__sm'>
                        {`import 'rds/dist/index.css'`}
                    </CodeBlock>
                    <Card
                        className='rds-m_top__sm'
                        headerTitle='You can place this anywhere in your React project'
                        headerText='However, we recommend keeping this at your index.js file'
                        headerSize={HeaderSizes.XSMALL}
                    />
                </div>
            </div>
        </section>
    )
}

export default GettingStarted
