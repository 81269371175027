import CardTemplate from '../components/Components/CardTemplate'
import CollapsibleTemplate from '../components/Components/CollapsibleTemplate'
import ChatTemplate from '../components/Components/ChatTemplate'
import FormTemplate from '../components/Components/FormTemplate'
import HeaderTemplate from '../components/Components/HeaderTemplate'
import ButtonTemplate from '../components/Components/ButtonTemplate'
import StatesTemplate from '../components/Components/StatesTemplate'
import GraphTemplate from '../components/Components/GraphTemplate'
import IconTemplate from '../components/Components/IconTemplate'
import TableTemplate from '../components/Components/TableTemplate'
import TagTemplate from '../components/Components/TagTemplate'
import BreadcrumbsTemplate from '../components/Components/BreadcrumbsTemplate'
import FormItemTemplate from '../components/Components/FormItemTemplate'
import FormSectionTemplate from '../components/Components/FormSectionTemplate'
import ButtonGroupTemplate from '../components/Components/ButtonGroupTemplate'
import EmojiTemplate from '../components/Components/EmojiTemplate'
import TypographyTemplate from '../components/Components/TypographyTemplate'
import LogoTemplate from '../components/Components/LogoTemplate'
import IsologoTemplate from '../components/Components/IsologoTemplate'
import LabelTemplate from '../components/Components/LabelTemplate'
import PathTemplate from '../components/Components/PathTemplate'
import ProgressBarTemplate from '../components/Components/ProgressBarTemplate'
import TableItemTemplate from '../components/Components/TableItemTemplate'
import BlockTemplate from '../components/Components/BlockTemplate'
import RaterTemplate from '../components/Components/RaterTemplate'
import CarrouselTemplate from '../components/Components/CarrouselTemplate'

export const components = {
    block: BlockTemplate,
    breadcrumbs: BreadcrumbsTemplate,
    button: ButtonTemplate,
    'button-group': ButtonGroupTemplate,
    card: CardTemplate,
    carrousel: CarrouselTemplate,
    chat: ChatTemplate,
    collapsible: CollapsibleTemplate,
    emoji: EmojiTemplate,
    form: FormTemplate,
    'form-item': FormItemTemplate,
    'form-section': FormSectionTemplate,
    graph: GraphTemplate,
    header: HeaderTemplate,
    icon: IconTemplate,
    isologo: IsologoTemplate,
    label: LabelTemplate,
    logo: LogoTemplate,
    path: PathTemplate,
    'progress-bar': ProgressBarTemplate,
    rater: RaterTemplate,
    states: StatesTemplate,
    table: TableTemplate,
    'table-item': TableItemTemplate,
    tag: TagTemplate,
    typography: TypographyTemplate,
}
