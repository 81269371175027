import React from 'react'
import { ThemesNames, ThemeVariants } from '../constants'
import {
    borderRadiusRule,
    blackValue,
    borderRule,
    variantBackgroundValue,
} from '../constants/cssRules'
import styled, { ThemeContext } from 'styled-components'

const getBackgroundColor = props => {
    if (props.disabled) {
        return `var(--rds-colorDisabled${
            props.themeContext.scheme === ThemesNames.LIGHT ? 'Light' : 'Dark'
        })`
    }
    switch (props.variant) {
        case ThemeVariants.MAIN:
            return props.themeContext.main

        case ThemeVariants.SECONDARY:
            return props.themeContext.secondary

        case ThemeVariants.GRAY:
            return variantBackgroundValue(props)

        case ThemeVariants.DARK:
            return blackValue(props.themeContext)

        case ThemeVariants.GLOSSY:
            return variantBackgroundValue(props)

        default:
            return props.themeContext.scheme === ThemesNames.DARK
                ? '#141414'
                : 'white'
    }
}

const StyledBlock = styled.div`
    background: ${props => getBackgroundColor(props)};
    ${props => (props.roundedBorder ? borderRadiusRule(props) : '')}
    ${props => (props.bordered ? borderRule(props) : '')}
`

const Block = props => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <StyledBlock {...{ ...props, themeContext }}>
            {props.children}
        </StyledBlock>
    )
}

export default Block
