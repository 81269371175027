import React from 'react'
import Template from '../Layout/Template'
import { Isologo, Card } from 'rds'

const IsologoTemplate = () => {
    return (
        <Template
            hashValue='isologo'
            componentName='Isologo'
            overview={
                <Card>
                    <Isologo />
                </Card>
            }
            example={`
<Isologo />`}
            properties={[
                {
                    name: 'animated',
                    type: 'BOOLEAN',
                    description:
                        'Whether the Isologo should be animated or not, used in Loading component',
                },
                {
                    name: 'big',
                    type: 'BOOLEAN',
                    description: 'Whether the size should be big or not',
                },
            ]}
        />
    )
}

export default IsologoTemplate
