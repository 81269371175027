import React from 'react'
import Button from '../Button'
import { ButtonVariants } from '../../constants'
import ButtonGroup from '../Button/ButtonGroup'

const FormActions = ({ secondaryActions, submitLabel, submitCallback }) => {
    return (
        <section
            className='rds-m_top__sm rds-full-block rds-flexbox align-center justify-center main-action-section'
            align='center'
        >
            {secondaryActions ? (
                <ButtonGroup className='rds-form_btn-group'>
                    {secondaryActions.map(action => (
                        <Button
                            key={action.label}
                            variant={action.buttonVariant}
                            onClick={action.callback}
                            label={action.label}
                            icon={action.buttonIcon}
                        />
                    ))}
                </ButtonGroup>
            ) : null}
            <Button
                variant={ButtonVariants.MAIN}
                label={submitLabel || 'Submit'}
                onClick={submitCallback}
            />
        </section>
    )
}

export default React.memo(FormActions)
