import React from 'react'
import Template from '../Layout/Template'
import { Chat } from 'rds'

const BlockTemplate = () => {
    const sessionUserId = '1234567'

    const [messages, setMessages] = React.useState([])

    const handleNewMessage = message => {
        return new Promise(resolve => {
            setTimeout(() => {
                const newMessage = {
                    text: message,
                    createdAt: new Date().toISOString(),
                    createdBy: sessionUserId,
                }
                setMessages(messages => [...messages, newMessage])
                resolve()
            }, 1000)
        })
    }

    const handleFetchMessages = () => {
        return new Promise(resolve => {
            setTimeout(() => {
                setMessages(messages => messages)
                resolve()
            }, 500)
        })
    }

    return (
        <Template
            hashValue='chat'
            componentName='Chat'
            overview={
                <Chat
                    onFetch={handleFetchMessages}
                    onSubmit={handleNewMessage}
                    sessionUserId={sessionUserId}
                    messages={messages}
                    useInterval
                />
            }
            example={`
<Chat
    onFetch={handleFetchMessages}
    onSubmit={handleNewMessage}
    sessionUserId={sessionUserId}
    messages={messages}
/>`}
            properties={[
                {
                    name: 'sessionUserId',
                    type: 'STRING',
                    description:
                        "ID of the current User. This is used for identifying user's messages and differentiate them from others",
                    required: true,
                },
                {
                    name: 'messages',
                    type: 'ARRAY',
                    description: 'Dynamic list of formatted messages',
                    required: true,
                },
                {
                    name: '→',
                    content: 'text',
                    type: 'STRING',
                    description: 'Text content of the message',
                    required: true,
                },
                {
                    name: '→',
                    content: 'createdBy|createdBy._id',
                    type: 'STRING',
                    description:
                        'ID to compare with the given "sessionUserId" one',
                    required: true,
                },
                {
                    name: '→',
                    content: 'createdAt',
                    type: 'STRING',
                    description: "Message's timestamp",
                    required: true,
                },
                {
                    name: 'onSubmit',
                    type: 'FUNCTION',
                    description:
                        'Callback to be excecuted when new message is submitted',
                },
                {
                    name: 'onFetch',
                    type: 'FUNCTION',
                    description: 'Callback called to refresh messages list',
                },
                {
                    name: 'useInterval',
                    type: 'BOOLEAN',
                    description:
                        'If true, calls "onFetch" callback each given interval',
                },
                {
                    name: 'delay',
                    type: 'NUMBER',
                    description:
                        'If "useInterval" is set to true, lets you customize the miliseconds between fetches. Default is 400',
                },
                {
                    name: 'emptyStateTitle',
                    type: 'STRING',
                    description: "Empty state's title",
                },
                {
                    name: 'emptyStateText',
                    type: 'STRING',
                    description: "Empty state's text",
                },
                {
                    name: 'messagePlaceholder',
                    type: 'STRING',
                    description: "New message box's placeholder",
                },
            ]}
        />
    )
}

export default BlockTemplate
