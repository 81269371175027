import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Block from './Block'
import Header from './Header'
import { HeaderSizes, ThemeVariants } from '../constants'
import { borderRadiusRule } from '../constants/cssRules'

const StyledProgressBar = styled.div`
    ${props => `
    background: linear-gradient(90deg, ${props.themeContext.main}, ${
        props.themeContext.secondary
    });
    ${borderRadiusRule(props)}
`}
`

const ProgressBar = ({
    progress,
    showPercentage,
    text = "Please wait, we're working on it...",
}) => {
    const themeContext = useContext(ThemeContext)
    return (
        <div style={{ display: 'inline-block', minWidth: '250px' }}>
            <div className='rds-flexbox align-center direction-column'>
                {showPercentage ? (
                    <Header
                        title={`${progress}%`}
                        text={text}
                        size={HeaderSizes.MEDIUM}
                        alignCenter
                    />
                ) : null}
                <Block
                    roundedBorder
                    variant={ThemeVariants.GRAY}
                    className='progress-bar_wrapper rds-full-block rds-m_top__sm'
                >
                    <StyledProgressBar
                        themeContext={themeContext}
                        className='progress-bar'
                        style={{ width: `${progress}%` }}
                    />
                </Block>
            </div>
        </div>
    )
}

export default ProgressBar
