import React from 'react'
import Card from './../../Card'
import Header from './../../Header'
import Tag from '../../Tag'
import { HeaderSizes, ThemeVariants } from '../../../constants'

const LookupResultItem = ({ item, handleSelectItem, locked }) => {
    return (
        <Card
            style={locked ? { opacity: 0.5, cursor: 'not-allowed' } : null}
            onClick={!locked ? () => handleSelectItem(item) : null}
            className='rds-full-block rds-flexbox align-center justify-between'
        >
            <div className='rds-flexbox align-center'>
                {item.imgPreview ? (
                    <img
                        className='rds-item-browser_result-item_img rds-m_right__sm'
                        src={item.imgPreview}
                        alt={item.label}
                    />
                ) : null}
                <Header
                    title={item.label}
                    text={item.description}
                    size={HeaderSizes.XSMALL}
                />
            </div>
            {item.stock !== undefined ? (
                locked ? (
                    <Tag
                        className='rds-m_top__sm'
                        variant={ThemeVariants.ERROR}
                    >
                        No items left
                    </Tag>
                ) : item.stock !== Infinity ? (
                    <Tag className='rds-m_top__sm'>{item.stock} left</Tag>
                ) : null
            ) : null}
        </Card>
    )
}

export default LookupResultItem
