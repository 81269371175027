import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { ThemeVariants } from '../../constants'
import {
    backgroundRule,
    colorRule,
    borderRadiusRule,
    borderRule,
    borderValue,
    fontSizeRule,
} from '../../constants/cssRules'

const StyledTextarea = styled.textarea`
    ${props => `
    ${backgroundRule({
        ...props,
        variant: props.disabled ? ThemeVariants.GRAY : props.variant,
    })}
    ${fontSizeRule('14px')}
    ${colorRule(props)}
    ${borderRadiusRule(props)}
    ${borderRule(props)}
    &:hover {
        ${props.disabled ? '' : borderRule(props, true)}
    }
    &:focus {
        outline: ${props.disabled ? '' : borderValue(props, true)};
    }
`}
`

const Textarea = props => {
    const themeContext = useContext(ThemeContext)
    return (
        <StyledTextarea
            {...props}
            themeContext={themeContext}
            autoComplete='off'
        />
    )
}

export default Textarea
