import React, { useState, useEffect } from 'react'
import Header from './../Header'
import Loading from '../States/Loading'
import ProgressBar from './../ProgressBar'
import { HeaderSizes } from '../../constants'
import ComponentError from '../States/ComponentError'
import FormActions from './FormActions'

const Form = ({
    children,
    className,
    submitCallback,
    secondaryActions,
    submitLabel,
    doubleActionsUI,
    submitProgress,
    submittingText,
    title,
}) => {
    const [loaded, setLoaded] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        setTimeout(() => setLoaded(true), 1)
    }, [])

    const handleSubmit = async e => {
        e.preventDefault()
        setSubmitting(true)
        await submitCallback()
        setSubmitting(false)
    }

    const submitClick = () => {
        const hiddenSubmitButton = document.getElementById('main-form-submit')
        hiddenSubmitButton.click()
    }

    if (!submitCallback) {
        return (
            <ComponentError
                componentName='Form'
                errorMessage="A 'submitCallback' prop is needed"
            />
        )
    }
    return (
        <form
            className={`rds-form ${className || ''} ${
                loaded ? 'loaded' : 'unloaded'
            } ${submitting ? 'rds-form_submitting' : ''}`}
            onSubmit={handleSubmit}
        >
            {title ? <Header title={title} size={HeaderSizes.MEDIUM} /> : null}
            {submitting ? (
                <div
                    className={`rds-full-block rds-m_top ${className}`}
                    align='center'
                >
                    {submitProgress !== null && submitProgress !== undefined ? (
                        <React.Fragment>
                            <ProgressBar
                                progress={submitProgress}
                                text={submittingText}
                                showPercentage
                            />
                        </React.Fragment>
                    ) : (
                        <Loading />
                    )}
                </div>
            ) : (
                doubleActionsUI && (
                    <FormActions
                        submitLabel={submitLabel}
                        submitCallback={submitClick}
                        secondaryActions={secondaryActions}
                    />
                )
            )}
            <section
                className={`rds-form_content rds-full-block rds-grid rds-grid_3-columns ${
                    doubleActionsUI ? 'rds-m_top__sm' : ''
                }`}
            >
                {children}
            </section>
            {!submitting ? (
                <FormActions
                    submitLabel={submitLabel}
                    submitCallback={submitClick}
                    secondaryActions={secondaryActions}
                />
            ) : null}
            <input
                id='main-form-submit'
                type='submit'
                style={{ display: 'none' }}
            />
        </form>
    )
}

export default Form
