import React from 'react'
import styled from 'styled-components'
import {
    colorRule,
    fontSizeRule,
    textDecorationRule,
} from '../../constants/cssRules'

const StyledH3 = styled.h3`
    ${props => `
        ${colorRule(props)}
        ${fontSizeRule('16px')}
        ${textDecorationRule(props.variant)}
    `}
`

const H3 = props => (
    <StyledH3
        {...props}
        style={
            props.onClick ? { cursor: 'pointer', ...props.style } : props.style
        }
    >
        {props.children}
    </StyledH3>
)

export default H3
