import React from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = () => {
    const { pathname } = useLocation()

    React.useEffect(() => {
        const el = document.getElementById('main-content')
        if (el && el.scrollTop) {
            el.scrollTo(0, 0)
        }
    }, [pathname])

    return null
}

export default ScrollToTop
