import React from 'react'
import Template from '../Layout/Template'
import { Graph } from 'rds'

const GraphTemplate = () => {
    return (
        <Template
            hashValue='graphs'
            componentName='Graph'
            overview={
                <div className='rds-grid rds-grid_2-columns'>
                    <Graph
                        mainFieldName='value'
                        itemFieldName='label'
                        itemDescriptionFieldName='description'
                        values={[
                            {
                                id: 1,
                                label: 'Group 1',
                                description: 'A description of the group',
                                value: 5,
                            },
                            {
                                id: 2,
                                label: 'Group 2',
                                description: 'A description of the group',
                                value: 10,
                            },
                            {
                                id: 3,
                                label: 'Group 3',
                                description: 'A description of the group',
                                value: 8,
                            },
                            {
                                id: 3,
                                label: 'Group 3',
                                description: 'A description of the group',
                                value: 6,
                            },
                        ]}
                        biggestValue={10}
                    />
                </div>
            }
            example={`
<Graph
    mainFieldName={String}
    itemFieldName={String}
    itemDescriptionFieldName={String}
    biggestValue={Number}
    values={Array}
/>`}
            properties={[
                {
                    name: 'mainFieldName',
                    type: 'STRING',
                    description: 'Name of the Y axis field to display',
                    required: true,
                },
                {
                    name: 'itemFieldName',
                    type: 'STRING',
                    description: 'Name of the X axis field to display',
                    required: true,
                },
                {
                    name: 'itemDescriptionFieldName',
                    type: 'STRING',
                    description:
                        'Name of the X axis field containing a description to display',
                },
                {
                    name: 'biggestValue',
                    type: 'NUMBER',
                    description: 'Maximum number value of the Y axis',
                    required: true,
                },
                {
                    name: 'values',
                    type: 'ARRAY',
                    description: 'Array of objects to be mapped',
                    required: true,
                },
            ]}
        />
    )
}

export default GraphTemplate
