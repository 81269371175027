import React from 'react'
import Template from '../Layout/Template'
import { Path, Typography, TypographyTypes } from 'rds'

const ITEMS = [
    { title: 'New', text: 'Lorem ipsum dolor sit amet' },
    {
        title: 'Confirmed',
        text: 'Lorem ipsum dolor sit amet',
    },
    {
        title: 'In Progress',
        text: 'Lorem ipsum dolor sit amet',
    },
    {
        title: 'Delivered',
        text: 'Lorem ipsum dolor sit amet',
    },
]

const PathTemplate = () => {
    const [value, setValue] = React.useState('New')
    const handlePathChange = itemId => {
        setValue(itemId)
    }
    return (
        <Template
            hashValue='path'
            componentName='Path'
            overview={
                <div>
                    <Typography type={TypographyTypes.H4}>Standard</Typography>
                    <Path
                        className='rds-m_top__sm rds-m_bottom__md'
                        items={ITEMS}
                        onChange={handlePathChange}
                        value={value}
                    />
                    <Typography type={TypographyTypes.H4}>Linear</Typography>
                    <Path
                        className='rds-m_top__sm rds-m_bottom__md'
                        items={ITEMS}
                        onChange={handlePathChange}
                        value={value}
                        linear
                    />
                </div>
            }
            example={`
<Path
    items={ITEMS}
    onChange={handlePathChange}
    value={value}
    linear
/>`}
            properties={[
                {
                    name: 'items',
                    type: 'ARRAY',
                    required: true,
                    description: 'Array of Path Items',
                },
                {
                    name: '→',
                    content: 'title',
                    type: 'STRING',
                    required: true,
                    description: 'Title of the Path Item. It must be unique',
                },
                {
                    name: '→',
                    content: 'text',
                    type: 'STRING',
                    description: 'Text of the Path Item',
                },
                {
                    name: 'onClick',
                    type: 'FUNCTION',
                    description:
                        'Function executed when onclick event is triggered',
                },
                {
                    name: 'className',
                    type: 'STRING',
                    description: 'CSS class string',
                },
                {
                    name: 'id',
                    type: 'STRING',
                    description: 'HTML id attribute',
                },
                {
                    name: 'style',
                    type: 'OBJECT',
                    description: 'CSS styles object',
                },
            ]}
        />
    )
}

export default PathTemplate
