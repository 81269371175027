import React from 'react'
import {
    Header,
    Card,
    HeaderSizes,
    ThemeVariants,
    Typography,
    TypographyTypes,
    cssRules,
} from 'rds'

const Theming = () => {
    return (
        <section id='theming' className='rds-m_top'>
            <Header
                title='Theming'
                text='We have our default theme settings, but RDS offers you the chance of creating your own'
            />
            <Card
                className='rds-m_top__sm rds-grid rds-grid_3-columns'
                style={{
                    padding: 'unset',
                    columnGap: 0,
                    gridTemplateColumns: '39.333% 33.333% 27.333%',
                }}
            >
                <Card noBorderRadius variant={ThemeVariants.MAIN}>
                    <Typography
                        variant={ThemeVariants.WHITE}
                        type={TypographyTypes.H2}
                    >
                        {cssRules.COLOR_PRIMARY_DEFAULT}
                    </Typography>
                    <Header
                        className='rds-m_top__sm'
                        title='Main'
                        text='This color is used by every main action component and some details'
                        size={HeaderSizes.SMALL}
                        variant={ThemeVariants.WHITE}
                    />
                </Card>
                <Card noBorderRadius variant={ThemeVariants.SECONDARY}>
                    <Typography
                        variant={ThemeVariants.DARK}
                        type={TypographyTypes.H2}
                    >
                        {cssRules.COLOR_SECONDARY_DEFAULT}
                    </Typography>
                    <Header
                        className='rds-m_top__sm'
                        title='Secondary'
                        text='This one is used for accents and secondary actions'
                        size={HeaderSizes.SMALL}
                        variant={ThemeVariants.DARK}
                    />
                </Card>
                <Card noBorderRadius variant={ThemeVariants.DARK}>
                    <Typography
                        variant={ThemeVariants.WHITE}
                        type={TypographyTypes.H2}
                    >
                        #011637
                    </Typography>
                    <Header
                        className='rds-m_top__sm'
                        title='Dark'
                        text='This color is used for black typography and some dark components'
                        size={HeaderSizes.SMALL}
                        variant={ThemeVariants.WHITE}
                    />
                </Card>
            </Card>
        </section>
    )
}

export default Theming
