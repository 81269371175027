export const TypographyTypes = Object.freeze({
    H1: 'H1',
    H2: 'H2',
    H3: 'H3',
    H4: 'H4',
    P: 'P',
})

export const Icons = Object.freeze({
    ADD: 'AddOne',
    ARROW: 'Left',
    BASKET: 'MallBag',
    BIN: 'Delete',
    BLOCKS: 'GridFour',
    CALENDAR: 'Calendar',
    CART: 'Shopping',
    CASE: 'Briefcase',
    CATEGORY: 'Box',
    CLOCK: 'Time',
    CONTACT: 'People',
    DASHBOARD: 'LayoutFour',
    DESKTOP: 'Computer',
    DUEDATE: 'Calendar',
    DUPLICATE: 'Copy',
    EDIT: 'Edit',
    EMPTY: 'RoadSignBoth',
    FILL: 'Fill',
    GMAIL: 'Mail',
    GOOGLEDRIVE: 'CloudStorage',
    GRAPH: 'Ranking',
    GRID: 'GridNine',
    HASHTAG: 'HashtagKey',
    HEART: 'Like',
    INTERLINING: 'AutoLineHeight',
    KEY: 'KeyOne',
    LARGEBLOCKS: 'Column',
    LIST: 'ListTwo',
    MAGIC: 'Magic',
    NOTE: 'Notes',
    PHONE: 'PhoneTelephone',
    PICTURE: 'PictureOne',
    PIN: 'LocalTwo',
    PRODUCT: 'ShoppingBagOne',
    RELOAD: 'Refresh',
    REPORT: 'ChartProportion',
    RESTORE: 'Redo',
    SALE: 'Buy',
    SAVE: 'CheckSmall',
    SEARCH: 'Search',
    SETTINGS: 'SettingTwo',
    SETTINGS_SLIDERS: 'SettingConfig',
    SIGNOUT: 'Logout',
    SMARTPHONE: 'Iphone',
    SQUARE: 'Square',
    STAR: 'Star',
    STORE: 'Application',
    SUCCESS: 'Success',
    TAG: 'TagOne',
    USER: 'User',
    USERPICTURE: 'Me',
    VIEW: 'ListView',
    WARNING: 'Caution',
})

export const ToastTypes = Object.freeze({
    SUCCESS: 'success',
    WARNING: 'warning',
    INFO: 'info',
    ERROR: 'error',
})

export const TOAST_MS_SHOWN = 3000

export const ThemeVariants = Object.freeze({
    MAIN: 'main',
    MAIN_LIGHT: 'main-light',
    SECONDARY: 'secondary',
    SECONDARY_LIGHT: 'secondary-light',
    ERROR: 'error',
    WHITE: 'white',
    DARK: 'dark',
    WARNING: 'warning',
    GRAY: 'gray',
    GREEN: 'green',
    GLOSSY: 'glossy',
    LINE_THROUGH: 'line-through',
    PURPLE: 'purple',
    TRANSPARENT: 'transparent',
    DASHED: 'dashed',
})

export const ButtonVariants = Object.freeze({
    BLACK: 'black',
    BLUE: 'blue',
    DARK: 'dark',
    DANGER: 'danger',
    DANGER_SECONDARY: 'danger_secondary',
    MAIN: 'main',
    SECONDARY: 'secondary',
    TRANSPARENT: 'transparent',
    WARNING: 'warning',
    WHITE: 'white',
})

export const ThemesNames = Object.freeze({
    LIGHT: 'THEME_LIGHT',
    DARK: 'THEME_DARK',
})

export const Themes = [
    {
        id: ThemesNames.LIGHT,
        name: ThemesNames.LIGHT,
        label: 'Claro',
    },
    {
        id: ThemesNames.DARK,
        name: ThemesNames.DARK,
        label: 'Oscuro',
    },
]

export const HeaderSizes = Object.freeze({
    XSMALL: 'XSMALL',
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
    BIG: 'BIG',
})

export const FormItemTypes = Object.freeze({
    CARD_SELECT: 'CARD_SELECT',
    CHECKBOX: 'CHECKBOX',
    COLOR: 'color',
    DATE_PICKER: 'DATE_PICKER',
    DRIVE_ATTACHMENT: 'DRIVE_ATTACHMENT',
    EMAIL: 'email',
    FILES_PICKER: 'FILES_PICKER',
    LOOKUP: 'LOOKUP',
    MULTI_SELECT: 'MULTI_SELECT',
    NUMBER: 'number',
    PASSWORD: 'password',
    RANGE: 'range',
    SELECT: 'select',
    TAG_MULTI_SELECT: 'TAG_MULTI_SELECT',
    TEXT: 'text',
    TEXTAREA: 'textarea',
})

export const FormSectionSizes = Object.freeze({
    SMALL: 'rds-form-section_small',
    MEDIUM: 'rds-form-section_medium',
    BIG: 'rds-form-section_big',
})

export const FormItemSizes = Object.freeze({
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
    BIG: 'BIG',
})

export const EmojiTypes = Object.freeze({
    ALERT: `🚨`,
    PAID: `💰`,
    DELIVERED: `🚚`,
    CALENDAR: `📅`,
    CONFETTI: `🎉`,
    WARNING: `⚠️`,
})

export const TableItemTypes = Object.freeze({
    TEXT: 'TEXT',
    IMAGE: 'IMAGE',
    LINK: 'LINK',
    TAG: 'TAG',
})
