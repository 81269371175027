import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import {
    cardBackgroundValue,
    borderValue,
    borderRadiusValue,
    softShadowRule,
    boxShadowValue,
} from '../../constants/cssRules'
import Header from '../Header'

export const StyledCard = styled.article`
    ${props => `
    ${softShadowRule(props)}
    background: ${cardBackgroundValue(props)};
    border: ${borderValue(props)};
    border-radius: ${props.noBorderRadius ? '' : borderRadiusValue(props)};
    box-shadow: ${boxShadowValue(props)};
    ${props.onClick ? 'cursor: pointer;' : ''}
`}
`

const StyledCardHeader = styled.div(props =>
    props.hasContent
        ? {
              marginTop: `calc(var(--rds-spacingMedium) * -1)`,
              padding: `var(--rds-spacingMedium)`,
              marginLeft: `calc(var(--rds-spacingMedium) * -1)`,
              width: `calc(var(--rds-spacingMedium) * 2 + 100%)`,
              marginBottom: `var(--rds-spacingMedium)`,
              borderBottom: `${borderValue(props)}`,
          }
        : null,
)

const Card = ({
    children,
    className,
    onClick,
    style,
    onDoubleClick,
    onMouseOver,
    onMouseLeave,
    variant,
    id,
    noBorderRadius,
    align,
    headerTitle,
    headerText,
    headerSize,
    headerVariant,
    headerIcon,
}) => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <StyledCard
            themeContext={themeContext}
            className={`rds-card card rds-relative rds-p_around__md ${
                className || ''
            }`}
            style={style}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            variant={variant}
            id={id}
            align={align}
            noBorderRadius={noBorderRadius}
        >
            {headerTitle ? (
                <StyledCardHeader
                    themeContext={themeContext}
                    className='rds-card_header'
                    hasContent={!!children}
                >
                    <Header
                        title={headerTitle}
                        text={headerText}
                        size={headerSize}
                        variant={headerVariant}
                        icon={headerIcon}
                    />
                </StyledCardHeader>
            ) : null}
            {children}
        </StyledCard>
    )
}

export default Card
