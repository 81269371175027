import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { borderRadiusValue, getCSSVariable } from '../../constants/cssRules'

const StyledIsoLogo = styled.div(props => ({
    borderRadius: borderRadiusValue(props),
    width: props.big ? 20 : 15,
    height: props.big ? 20 : 15,
    display: 'inline-block',
    transform: 'rotate(45deg)',
    background: `linear-gradient(-${props.rotation}deg, ${props.themeContext.secondary}, ${props.themeContext.main} 65%)`,
    backgroundPosition: getCSSVariable('isologoBackgroundPosition'),
}))

const Isologo = props => {
    const { children, className = '', animated } = props
    const themeContext = React.useContext(ThemeContext)
    const [rotation, setRotation] = React.useState(45)

    React.useEffect(() => {
        if (animated) {
            setInterval(() => {
                setRotation(rotation => {
                    if (rotation >= 360) {
                        return 0
                    }
                    return rotation + 1
                })
            }, 10)
        }
    }, [animated])

    return (
        <StyledIsoLogo
            {...props}
            rotation={rotation}
            themeContext={themeContext}
            className={`rds-isologo ${className}`}
        >
            {children}
        </StyledIsoLogo>
    )
}

export default Isologo
