import React from 'react'
import { Icons } from '../../../constants'
import Button from '../../Button'
import FilesPickerFile from './FilesPickerFile'

const FilesPicker = ({
    id,
    className,
    accept,
    uploadLabel,
    multiple,
    onChange,
    value = [],
}) => {
    const fileInputRef = React.useRef(null)

    const handleChange = newValue => {
        if (typeof onChange === 'function') {
            onChange({
                target: {
                    id,
                    value: newValue,
                },
            })
        }
    }

    const handleFileSelect = e => {
        if (e.target.files.length) {
            const currentFile = e.target.files[e.target.files.length - 1]
            const formattedFile = {
                filename: currentFile.name,
                file: currentFile,
                preview: URL.createObjectURL(currentFile),
            }
            let newValue = []
            if (!multiple) {
                newValue = [formattedFile]
            } else {
                newValue = [...value, formattedFile]
            }
            handleChange(newValue)
        }
    }

    const handleAddFileClick = () => fileInputRef.current.click()

    const handleRemoveFileClick = fileRemoved => {
        fileInputRef.current.value = null
        handleChange(value.filter(f => f !== fileRemoved))
    }

    return (
        <div id={id} className={`rds-m_top__sm ${className || ''}`}>
            <div style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
                {value?.map((f, i) => (
                    <FilesPickerFile
                        removeCallback={() => handleRemoveFileClick(f)}
                        fileData={f}
                        key={i}
                    />
                ))}
            </div>
            <div>
                <input
                    ref={fileInputRef}
                    accept={accept}
                    id='pictures-picker-input'
                    onChange={handleFileSelect}
                    style={{ display: 'none' }}
                    multiple={multiple ? 'multiple' : null}
                    type='file'
                />
                <Button
                    icon='Upload'
                    label={uploadLabel || 'Upload a file'}
                    onClick={handleAddFileClick}
                />
            </div>
        </div>
    )
}

export default FilesPicker
