import React from 'react'
import {
    Block,
    Typography,
    ThemeVariants,
    Label,
    ThemeContext,
    cssRules,
} from 'rds'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { components } from '../../constants'

const StyledNavLinkInner = styled.div`
    > :nth-child(1) {
        transition: 0.2s all;
        padding: 0 10px;
        ${props =>
            `
            & span {
                z-index: 1;
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: 0.2s all;
                ${cssRules.borderRadiusRule(props)}
            }
        `}
        ${props =>
            props.active
                ? `
            font-weight: bold;
            padding: 5px 10px;
            &::before {
                background-color: ${cssRules.buttonBackgroundColorValue(props)};
            }
        `
                : ''}
    }
`

const SidebarSubtitle = ({ children, link }) => {
    const themeContext = React.useContext(ThemeContext)
    const location = useLocation()
    const [active, setActive] = React.useState(false)

    React.useEffect(() => {
        let newState
        if (location.pathname) {
            const splittedLocationPath = location.pathname.split(link)
            if (splittedLocationPath.length > 1 && !splittedLocationPath[1]) {
                newState = true
            } else if (location.hash && link.includes(location.hash)) {
                newState = true
            }
        }
        setActive(newState)
    }, [link, location])

    return (
        <NavLink className='rds-full-block' to={link}>
            <StyledNavLinkInner
                className='rds-flexbox rds-m_top__sm'
                active={active}
                themeContext={themeContext}
            >
                <Typography
                    className='rds-relative'
                    variant={active ? ThemeVariants.MAIN : null}
                >
                    <span className='rds-relative'>{children}</span>
                </Typography>
            </StyledNavLinkInner>
        </NavLink>
    )
}

const Sidebar = () => {
    const location = useLocation()

    React.useEffect(() => {
        if (!location.hash) {
            return
        }
        const hashSplitted = location.hash.split('#')
        if (hashSplitted.length > 1) {
            const scrollToId = hashSplitted[1]
            const scrollToEl = document.getElementById(scrollToId)
            if (scrollToEl) {
                scrollToEl.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
            }
        }
    }, [location.hash])

    return (
        <div className='aside_wrapper'>
            <Block
                variant={ThemeVariants.GRAY}
                className='aside rds-p_around__md rds-overflow_auto'
            >
                <div className='rds-full-block'>
                    <Label>First steps</Label>
                    <SidebarSubtitle link={`/#getting-started`}>
                        Getting started
                    </SidebarSubtitle>
                    <SidebarSubtitle link={`/#components`}>
                        Components
                    </SidebarSubtitle>
                </div>
                <div className='rds-full-block rds-m_top__md'>
                    <Label>Customize</Label>
                    <SidebarSubtitle link={`/#theming`}>
                        Theming
                    </SidebarSubtitle>
                    <SidebarSubtitle link={`/#css`}>CSS</SidebarSubtitle>
                </div>
                <div className='rds-full-block rds-m_top__md'>
                    <Label>Components</Label>
                    {Object.keys(components).map(componentId => (
                        <SidebarSubtitle
                            key={componentId}
                            link={`/components/${componentId}`}
                        >
                            {componentId}
                        </SidebarSubtitle>
                    ))}
                </div>
                <div className='rds-full-block rds-m_top__md'>
                    <Label>More</Label>
                    <SidebarSubtitle link={`/#troubleshoot`}>
                        Troubleshoot
                    </SidebarSubtitle>
                    <SidebarSubtitle link={`/#donate`}>Donate</SidebarSubtitle>
                </div>
            </Block>
        </div>
    )
}

export default Sidebar
