import React from 'react'
import Counter from '../Counter'
import Button from './../../Button'
import Card from './../../Card'
import Header from './../../Header'
import { HeaderSizes, Icons } from '../../../constants'

const LookupSelectedItem = ({
    item = {},
    onDeselect,
    onCounterChange,
    full,
    position,
}) => {
    return (
        <Card
            className={`rds-item-browser_result-item rds-p_around__sm rds-flexbox align-center justify-between ${
                full ? 'rds-full-block' : ''
            } ${position > 0 ? 'rds-m_top__sm' : ''}`}
        >
            <div className='rds-flexbox align-center'>
                {item.imgPreview ? (
                    <img
                        className='rds-item-browser_result-item_img rds-m_right__sm'
                        src={item.imgPreview}
                        alt={item.label}
                    />
                ) : null}
                <Header
                    title={item.label}
                    text={item.description}
                    size={HeaderSizes.XSMALL}
                />
            </div>
            {onCounterChange ? (
                <Counter
                    startingAt={1}
                    steps={1}
                    minValue={1}
                    maxValue={item.maxAmount}
                    keyName={item.id}
                    onChange={onCounterChange}
                    value={item.amount}
                />
            ) : null}
            <Button icon='Delete' onClick={() => onDeselect(item)} />
        </Card>
    )
}

export default LookupSelectedItem
