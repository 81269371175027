import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Block, ThemesNames, cssRules } from 'rds'
import { ThemeProvider } from 'styled-components'
import 'rds/dist/index.css'

import Head from './components/Layout/Head'
import Sidebar from './components/Layout/Sidebar'
import Components from './pages/Components'
import Main from './pages/Main'
import ScrollTop from './components/Layout/ScrollTop'

const App = () => {
    const [headCollapsed, setHeadCollapsed] = React.useState(false)
    const [themeScheme, setThemeScheme] = React.useState(ThemesNames.LIGHT)

    const theme = {
        // This is to avoid black theme when no
        // userConfig is retrieved
        main: cssRules.COLOR_PRIMARY_DEFAULT,
        secondary: cssRules.COLOR_SECONDARY_DEFAULT,
        scheme: themeScheme,
        borderRadius: 5,
        borderWidth: 1,
    }

    React.useEffect(() => {
        const sessionScheme = localStorage.getItem('rds_scheme')
        if (sessionScheme) {
            setThemeScheme(sessionScheme)
        }
    }, [])

    const handleMainScroll = e => {
        if (e.target.id !== 'main-content') return
        if (e.target.scrollTop > 10) {
            setHeadCollapsed(true)
        } else {
            setHeadCollapsed(false)
        }
    }

    return (
        <Router basename={process.env.PUBLIC_URL}>
            <ThemeProvider theme={theme}>
                <ScrollTop />
                <Block
                    className='rds-full-block rds-flexbox'
                    style={{ flexDirection: 'column' }}
                >
                    <Head
                        setThemeScheme={setThemeScheme}
                        themeScheme={themeScheme}
                        collapsed={headCollapsed}
                    />
                    <div className='main-wrapper rds-full-block'>
                        <Sidebar />
                        <div
                            id='main-content'
                            className='rds-p_around'
                            onScroll={handleMainScroll}
                        >
                            <div id='main-content_inner'>
                                <Switch>
                                    <Route
                                        path='/components/:componentId'
                                        component={Components}
                                        exact
                                    />
                                    <Route path='/' component={Main} exact />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </Block>
            </ThemeProvider>
        </Router>
    )
}

export default App
