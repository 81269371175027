import React from 'react'
import styled from 'styled-components'
import Card from './../Card'
import { FormSectionSizes, HeaderSizes } from '../../constants'

const StyledFormSection = styled.div({
    width: '100%',
})

const FormSection = ({
    children,
    size = FormSectionSizes.BIG,
    title,
    description,
    className,
    style,
}) => {
    return (
        <StyledFormSection
            className={`rds-form-section ${size} ${className || ''}`}
            style={style}
        >
            <Card
                className='rds-full-block rds-form-section_card rds-overflow_visible'
                headerTitle={title}
                headerText={description}
                headerSize={HeaderSizes.SMALL}
            >
                <div className='rds-form-section_inner rds-grid rds-grid_2-columns'>
                    {children}
                </div>
            </Card>
        </StyledFormSection>
    )
}

export default FormSection
