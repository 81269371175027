import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { borderRadiusValue } from '../../constants/cssRules'

const StyledButtonGroup = styled.div`
    ${props => `
        overflow: hidden;
        width: fit-content;
        > * {
            &.rds-btn,
            & .rds-btn {
                margin: 0 !important;
                border-radius: 0;
            }
            &:first-child.rds-btn,
            &:first-child .rds-btn {
                border-top-left-radius: ${borderRadiusValue(props)};
                border-bottom-left-radius: ${borderRadiusValue(props)};
                ${props.size > 2 ? 'border-right: unset;' : ''}
            }
            &:last-child.rds-btn,
            &:last-child .rds-btn {
                border-top-right-radius: ${borderRadiusValue(props)};
                border-bottom-right-radius: ${borderRadiusValue(props)};
                ${props.size > 2 ? 'border-left: unset;' : ''}
            }
        }
    `}
`

const ButtonGroup = ({ children, className }) => {
    const themeContext = useContext(ThemeContext)
    return (
        <StyledButtonGroup
            themeContext={themeContext}
            className={`${className || ''} rds-flexbox align-center`}
            size={React.Children.count(children)}
        >
            {children}
        </StyledButtonGroup>
    )
}

export default ButtonGroup
