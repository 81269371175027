import React from 'react'
import styled from 'styled-components'
import {
    colorRule,
    fontSizeRule,
    textDecorationRule,
} from '../../constants/cssRules'

const StyledP = styled.p`
    ${props => `
        ${colorRule(props)}
        ${fontSizeRule('15px')}
        ${textDecorationRule(props.variant)}
    `}
`

const P = ({
    variant,
    themeContext,
    className,
    children,
    align,
    onClick,
    style,
}) => {
    return (
        <StyledP
            align={align}
            themeContext={themeContext}
            className={className}
            onClick={onClick && onClick}
            style={onClick ? { ...style, cursor: 'pointer' } : style}
            variant={variant}
        >
            {children}
        </StyledP>
    )
}

export default P
