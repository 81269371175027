import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import {
    colorValue,
    borderValue,
    borderRadiusValue,
    variantBackgroundValue,
} from '../constants/cssRules'
import { ThemeVariants, ThemesNames } from '../constants'
import Icon from './Icon'

const StyledTag = styled.span(props => ({
    color: colorValue(props),
    opacity: props.disabled ? 0.5 : null,
    border: borderValue(props),
    borderRadius: borderRadiusValue(props),
    background: variantBackgroundValue({
        ...props,
        variant: props.variant || ThemeVariants.GRAY,
    }),
    cursor: props.onClick ? 'pointer' : props.disabled ? 'not-allowed' : null,
    '& .rds-tag_inner': {
        color: props.variant === ThemeVariants.DARK ? 'white !important' : null,
    },
}))

const Tag = ({
    id,
    className,
    children,
    variant,
    style,
    onClick,
    title,
    disabled,
    icon,
}) => {
    const themeContext = React.useContext(ThemeContext)
    const processedClassName = `rds-tag ${className || ''} ${variant || ''}`

    const getIconVariant = () => {
        let iconVariant
        if (variant === ThemeVariants.DARK) {
            iconVariant = ThemeVariants.WHITE
        } else if (variant === ThemeVariants.TRANSPARENT || !variant) {
            iconVariant =
                themeContext.scheme === ThemesNames.DARK
                    ? ThemeVariants.WHITE
                    : variant
        } else {
            iconVariant = variant
        }
        return iconVariant
    }

    return (
        <StyledTag
            id={id}
            themeContext={themeContext}
            variant={variant}
            className={processedClassName}
            style={style}
            onClick={disabled ? undefined : onClick}
            title={title}
            disabled={disabled}
        >
            <span className='rds-tag_inner rds-flexbox align-center'>
                {icon ? (
                    <Icon
                        type={icon}
                        variant={getIconVariant()}
                        className={children ? 'rds-m_right__sm' : ''}
                    />
                ) : null}
                {children}
            </span>
        </StyledTag>
    )
}

export default React.memo(Tag)
