import React from 'react'
import { ThemeVariants } from '../../constants'
import Tag from '../Tag'

const TagMultiSelect = ({
    id,
    values,
    onSelect,
    value = [],
    max,
    disabled,
}) => {
    const handleSelect = selectedvalue => {
        if (max && selectedvalue.length === max) {
            return
        }
        const updatedValue = [...value]
        if (updatedValue.includes(selectedvalue)) {
            updatedValue.splice(updatedValue.indexOf(selectedvalue), 1)
        } else {
            updatedValue.push(selectedvalue)
        }
        onSelect(updatedValue)
    }

    return (
        <div id={id}>
            {values.map(option => {
                const isSelected = value.includes(option.id)
                return (
                    <Tag
                        className='rds-m_right__sm rds-m_bottom__sm'
                        variant={isSelected ? ThemeVariants.DARK : null}
                        disabled={disabled}
                        onClick={() => handleSelect(option.id)}
                        key={option.id}
                    >
                        {option.label}
                    </Tag>
                )
            })}
        </div>
    )
}

export default TagMultiSelect
