import React from 'react'
import Template from '../Layout/Template'
import {
    Header,
    HeaderSizes,
    IconTypes,
    ThemeVariants,
    Tag,
    Block,
    Card,
} from 'rds'

const HeaderTemplate = () => {
    return (
        <Template
            hashValue='headers'
            componentName='Header'
            overview={
                <>
                    <Card headerTitle='Sizes' headerSize={HeaderSizes.SMALL}>
                        <Header
                            title='A big header'
                            text='Some text or description here'
                        />
                        <Header
                            title='A medium header'
                            text='Some text or description here'
                            size={HeaderSizes.MEDIUM}
                            className='rds-m_top__md'
                        />
                        <Header
                            title='A small header'
                            text='Some text or description here'
                            size={HeaderSizes.SMALL}
                            className='rds-m_top__md'
                        />
                        <Header
                            title='An extra small header'
                            text='Some text or description here'
                            size={HeaderSizes.XSMALL}
                            className='rds-m_top__md'
                        />
                    </Card>
                    <Card
                        className='rds-m_top__sm'
                        headerTitle='Icons'
                        headerSize={HeaderSizes.SMALL}
                    >
                        <Header
                            title='A big header with icon'
                            text='Some text or description here'
                            icon={IconTypes.USER}
                            className='rds-m_top__sm'
                        />
                        <Header
                            title='A medium header with icon'
                            text='Some text or description here'
                            size={HeaderSizes.MEDIUM}
                            icon={IconTypes.BIN}
                            className='rds-m_top__md'
                        />
                        <Header
                            title='A small header with icon'
                            text='Some text or description here'
                            size={HeaderSizes.SMALL}
                            icon={IconTypes.CATEGORY}
                            className='rds-m_top__md'
                        />
                        <Header
                            title='An extra small header with icon'
                            text='Some text or description here'
                            size={HeaderSizes.XSMALL}
                            icon={IconTypes.PRODUCT}
                            className='rds-m_top__md'
                        />
                    </Card>
                    <Card
                        className='rds-m_top__sm'
                        headerTitle='Colors'
                        headerSize={HeaderSizes.SMALL}
                    >
                        <Header
                            title='Accented header'
                            text='Some text or description here'
                            icon={IconTypes.KEY}
                            variant={ThemeVariants.SECONDARY}
                            size={HeaderSizes.SMALL}
                            className='rds-m_top__sm'
                        />
                        <Header
                            title='Error header'
                            text='Some text or description here'
                            icon={IconTypes.WARNING}
                            variant={ThemeVariants.ERROR}
                            size={HeaderSizes.SMALL}
                            className='rds-m_top__sm'
                        />
                        <Block
                            variant={ThemeVariants.DARK}
                            className='rds-m_top__sm rds-p_around__sm'
                            style={{
                                display: 'inline-block',
                            }}
                        >
                            <Header
                                title='White header'
                                text='Some text or description here'
                                icon={IconTypes.VIEW}
                                variant={ThemeVariants.WHITE}
                                size={HeaderSizes.SMALL}
                            />
                        </Block>
                    </Card>
                    <Card
                        className='rds-m_top__sm'
                        headerTitle='Tags'
                        headerSize={HeaderSizes.SMALL}
                    >
                        <Header
                            title={
                                <>
                                    A tagged big header <Tag>some tag</Tag>
                                </>
                            }
                            text='Some text or description here'
                            size={HeaderSizes.MEDIUM}
                            className='rds-m_top__md'
                        />
                    </Card>
                    <Card
                        className='rds-m_top__sm'
                        headerTitle='Breadcrumb'
                        headerSize={HeaderSizes.SMALL}
                    >
                        <Header
                            title='My item'
                            text='Some text or description here'
                            size={HeaderSizes.MEDIUM}
                            breadcrumb={{
                                link: '#headers',
                                value: 'List of Items',
                            }}
                            className='rds-m_top__md'
                        />
                    </Card>
                </>
            }
            example={`
<Header
    title={String}
    text={String}
    size={HeaderSizes}
    variant={ThemeVariants}
    breadcrumb={Object}
    className={String}
    alignCenter={Boolean},
    icon={IconTypes}
    style={Object}
    onClick={Function}
/>`}
            properties={[
                {
                    name: 'title',
                    type: 'STRING',
                    description: 'Title of the Header',
                },
                {
                    name: 'text',
                    type: 'STRING',
                    description: 'Small text below',
                },
                {
                    name: 'size',
                    type: 'HeaderSizes',
                    description:
                        'Size of the Header, HeaderSizes.BIG as default',
                },
                {
                    name: 'variant',
                    type: 'ThemeVariants',
                    description: "import { ThemeVariants } from 'rds'",
                },
                {
                    name: 'breadcrumb',
                    type: 'OBJECT',
                    description: 'Breadcrumb settings',
                },
                {
                    name: '→',
                    content: 'link',
                    type: 'STRING',
                    description: `React path value`,
                },
                {
                    name: '→',
                    content: 'value',
                    type: 'STRING',
                    description: `Label to show`,
                },
                {
                    name: 'className',
                    type: 'STRING',
                    description: 'CSS class',
                },
                {
                    name: 'alignCenter',
                    type: 'BOOLEAN',
                    description:
                        'If true, the Header will be aligned to the center',
                },
                {
                    name: 'icon',
                    type: 'IconTypes',
                    description: 'Icon to display in the Header',
                },
                {
                    name: 'style',
                    type: 'OBJECT',
                    description: 'CSS Object',
                },
                {
                    name: 'onClick',
                    type: 'FUNCTION',
                    description:
                        'Callback to execute when the Header is clicked',
                },
                {
                    name: 'children',
                    type: 'FUNCTION | Node',
                    description: '',
                },
            ]}
        />
    )
}

export default HeaderTemplate
