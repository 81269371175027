import React from 'react'
import Template from '../Layout/Template'
import { Collapsible, HeaderSizes, Typography, TypographyTypes } from 'rds'

const CollapsibleTemplate = () => {
    return (
        <Template
            hashValue='collapsible'
            componentName='Collapsible'
            overview={
                <div>
                    <Collapsible
                        headerTitle="I'm collapsible"
                        headerText='Click me'
                        headerSize={HeaderSizes.SMALL}
                    >
                        <Typography type={TypographyTypes.P}>
                            Lorem ipsum dolor sit amet consecetur.
                        </Typography>
                    </Collapsible>
                </div>
            }
            example={`
<Collapsible
    id={String}
    className={String}
    headerTitle={String}
    headerText={String}
    headerSize={HeaderSizes}
>
    // ...
</Collapsible>`}
            properties={[
                {
                    name: 'className',
                    type: 'STRING',
                    description: 'CSS class string',
                },
                {
                    name: 'id',
                    type: 'STRING',
                    description: 'HTML id attribute',
                },
                {
                    name: 'headerTitle',
                    type: 'STRING',
                    description: "Collapsible's header title",
                },
                {
                    name: 'headerText',
                    type: 'STRING',
                    description: "Collapsible's header text",
                },
                {
                    name: 'headerSize',
                    type: 'HeaderSizes',
                    description: "Collapsible's header size",
                },
                {
                    name: 'children',
                    type: 'FUNCTION | Node',
                    description: "Collapsible's dropdown content",
                },
            ]}
        />
    )
}

export default CollapsibleTemplate
