import React, { useContext } from 'react'
import H1 from './H1'
import H2 from './H2'
import H3 from './H3'
import H4 from './H4'
import P from './P'
import { ThemeContext } from 'styled-components'
import { TypographyTypes } from '../../constants'

const components = Object.freeze({ H1, H2, H3, H4, P })

const Typography = ({
    type = TypographyTypes.P,
    children,
    className,
    variant,
    style,
}) => {
    const themeContext = useContext(ThemeContext)
    return React.createElement(
        components[type],
        {
            themeContext,
            style,
            className: `rds-typography ${className || ''}`,
            variant,
        },
        children,
    )
}

export default Typography
