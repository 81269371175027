import React from 'react'
import { CodeBlock as ReactCodeBlock, tomorrowNight } from 'react-code-blocks'
import { ThemeVariants } from '../constants'
import Tag from '../components/Tag'

const CodeBlock = ({ children, className, style, id, copyLabel = 'Copy' }) => {
    const handleCopy = () => {
        const textArea = document.createElement('textarea')
        textArea.value = children

        // Avoid scrolling to bottom
        textArea.style.top = '-100%'
        textArea.style.left = '-100%'
        textArea.style.position = 'fixed'

        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        // For mobile devices
        textArea.setSelectionRange(0, 99999)

        // Copy the text inside the text field
        navigator.clipboard.writeText(textArea.value)
    }

    return (
        <div
            className={`rds-code rds-relative ${className || ''}`}
            style={style}
            id={id}
        >
            <ReactCodeBlock
                text={children}
                language='jsx'
                showLineNumbers
                theme={tomorrowNight}
            ></ReactCodeBlock>
            <Tag
                onClick={handleCopy}
                variant={ThemeVariants.DARK}
                className='rds-code_copy rds-absolute'
                icon='Copy'
            >
                {copyLabel}
            </Tag>
        </div>
    )
}

export default CodeBlock
