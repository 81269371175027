import React from 'react'
import ComponentError from '../States/ComponentError'
import Breadcrumb from './Breadcrumb'

const Breadcrumbs = ({ className, id, onClick, style, value, values }) => {
    if (!values) {
        return (
            <ComponentError
                componentName='Breadcrumbs'
                errorMessage='A "values" prop needs to be provided'
            />
        )
    }
    return (
        <div
            className={`rds-breadcrumbs rds-flexbox align-center ${
                className || ''
            }`}
            id={id}
            style={style}
        >
            {values.map((v, i) => (
                <Breadcrumb
                    active={v.value === value}
                    isLastOne={i === values.length - 1}
                    key={v.value}
                    value={v.value}
                    onClick={onClick}
                >
                    {v.label}
                </Breadcrumb>
            ))}
        </div>
    )
}

export default Breadcrumbs
