import React from 'react'
import styled from 'styled-components'
import {
    colorRule,
    fontSizeRule,
    textDecorationRule,
} from '../../constants/cssRules'

const StyledH4 = styled.h4`
    ${props => `
        ${colorRule(props)}
        ${fontSizeRule('15px')}
        ${textDecorationRule(props.variant)}
    `}
`

const H4 = props => (
    <StyledH4
        {...props}
        style={
            props.onClick ? { cursor: 'pointer', ...props.style } : props.style
        }
    >
        {props.children}
    </StyledH4>
)

export default H4
