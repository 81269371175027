import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Block from '../Block'
import Header from '../Header'
import Tag from '../Tag'
import Typography from '../Typography'
import { hexDecrement } from '../../utils'
import { TypographyTypes, HeaderSizes, ThemeVariants } from './../../constants'
import ComponentError from '../States/ComponentError'
import Label from '../Label'
import { borderRadiusRule } from '../../constants/cssRules'

const StyledReportBar = styled.div`
    background-color: ${props => props.themeContext.main};
    ${props => borderRadiusRule(props)}
    &:hover {
        background-color: ${props => hexDecrement(props.themeContext.main, 50)};
    }
`

const Graph = ({
    values,
    biggestValue,
    mainFieldName,
    itemFieldName,
    itemDescriptionFieldName,
    yPrefix,
    xSuffix,
}) => {
    const themeContext = useContext(ThemeContext)
    if (!values || !mainFieldName || !itemFieldName || !biggestValue) {
        return (
            <ComponentError
                componentName='Graph'
                errorMessage={
                    <React.Fragment>
                        An <b>array of values</b>, a <b>main field name</b> and
                        an <b>item field name</b> are needed
                    </React.Fragment>
                }
            />
        )
    }
    return (
        <article>
            <div>
                <Label>{mainFieldName}</Label>
            </div>
            <div
                style={{
                    minHeight: '300px',
                    gridTemplateColumns: 'auto auto',
                    marginBottom: '100px',
                }}
                className='rds-grid rds-m_top__md'
            >
                <div
                    className='rds-full-block rds-flexbox direction-column justify-between'
                    style={{ height: '100%', width: 'max-content' }}
                    align='right'
                >
                    <div className='rds-flexbox align-center justify-between'>
                        <Tag>{`${yPrefix || ''} ${biggestValue}`}</Tag>
                        <Typography type={TypographyTypes.P}>-</Typography>
                    </div>
                    <div className='rds-flexbox align-center justify-between'>
                        <Tag>{`${yPrefix || ''} ${Math.round(
                            biggestValue / 1.5,
                        )}`}</Tag>
                        <Typography type={TypographyTypes.P}>-</Typography>
                    </div>
                    <div className='rds-flexbox align-center justify-between'>
                        <Tag>{`${yPrefix || ''} ${Math.round(
                            biggestValue / 2,
                        )}`}</Tag>
                        <Typography type={TypographyTypes.P}>-</Typography>
                    </div>
                    <div className='rds-flexbox align-center justify-between'>
                        <Tag>{`${yPrefix || ''} ${Math.round(
                            biggestValue / 3,
                        )}`}</Tag>
                        <Typography type={TypographyTypes.P}>-</Typography>
                    </div>
                    <div className='rds-flexbox align-center justify-between'>
                        <Tag>{`${yPrefix || ''} 0`}</Tag>
                        <Typography type={TypographyTypes.P}>-</Typography>
                    </div>
                </div>
                <div className='reports_bars rds-flexbox'>
                    {values.map(v => (
                        <article
                            key={v[mainFieldName]}
                            className='reports_bar-section rds-grid'
                            align='center'
                        >
                            <Block
                                roundedBorder
                                variant={ThemeVariants.GRAY}
                                className='reports_bar-wrapper rds-flexbox'
                            >
                                <StyledReportBar
                                    className='reports_bar'
                                    themeContext={themeContext}
                                    style={{
                                        height: `${
                                            (v[mainFieldName] / biggestValue) *
                                            100
                                        }%`,
                                    }}
                                >
                                    <Block
                                        roundedBorder
                                        variant={ThemeVariants.DARK}
                                        className='reports_bar_detail-panel'
                                    >
                                        <Header
                                            variant={ThemeVariants.WHITE}
                                            title={`${yPrefix || ''} ${
                                                v[mainFieldName]
                                            }`}
                                            size={HeaderSizes.XSMALL}
                                        />
                                    </Block>
                                </StyledReportBar>
                            </Block>
                            <div
                                className='rds-m_top__md'
                                style={{ padding: '5px' }}
                            >
                                <Header
                                    title={v[itemFieldName]}
                                    size={HeaderSizes.SMALL}
                                />
                                <Typography type={TypographyTypes.P}>
                                    {v[itemDescriptionFieldName]}
                                </Typography>
                            </div>
                        </article>
                    ))}
                </div>
            </div>
        </article>
    )
}

export default Graph
