import React from 'react'
import Isologo from './../Logo/Isologo'
import Typography from '../Typography'

const Loading = ({ tag, className = '' }) => {
    const getAnimation = () => (
        <div className='rds-loading rds-absolute'>
            <Isologo animated big />
        </div>
    )
    if (tag) {
        return (
            <div
                className={`rds-loading_wrapper rds-flexbox align-center justify-center ${className}`}
            >
                {getAnimation()}
                <Typography className='rds-m_left__sm'>
                    <b>{tag}</b>
                </Typography>
            </div>
        )
    }
    return getAnimation()
}

export default Loading
