import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { colorRule } from '../../constants/cssRules'

const SEPARATOR = '/'

const StyledBreadcrumbValue = styled.span`
    font-size: 14px;
    ${props => colorRule(props)}
    ${props => (props.active ? 'font-weight: bolder;' : '')}
    cursor: pointer;
`
const StyledBreadcrumbSeparator = styled.span`
    font-size: 14px;
    margin: 0 10px;
    cursor: default;
    ${props => colorRule(props)}
`

const Breadcrumb = ({ active, children, isLastOne, value, onClick }) => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <div
            className='rds-breadcrumbs_breadcrumb'
            onClick={
                typeof onClick === 'function' ? () => onClick(value) : null
            }
        >
            <span className='rds-flexbox align-center'>
                <StyledBreadcrumbValue
                    className='rds-text-variant_link'
                    themeContext={themeContext}
                    active={active}
                >
                    {children}
                </StyledBreadcrumbValue>
                {isLastOne ? null : (
                    <StyledBreadcrumbSeparator themeContext={themeContext}>
                        {SEPARATOR}
                    </StyledBreadcrumbSeparator>
                )}
            </span>
        </div>
    )
}

export default Breadcrumb
