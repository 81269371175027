import React from 'react'
import { useParams } from 'react-router-dom'
import { components } from '../constants'

const Components = props => {
    const params = useParams()
    return React.createElement(components[params.componentId], props)
}

export default Components
