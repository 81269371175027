import React from 'react'
import Template from '../Layout/Template'
import {
    Form,
    FormItem,
    FormSection,
    FormItemTypes,
    ButtonVariants,
    FormSectionSizes,
    IconTypes,
    FormItemSizes,
} from 'rds'

const DEFAULT_VALUES = [
    {
        id: 1,
        label: 'Option 1',
        description: 'This is the description of the first option',
    },
    {
        id: 2,
        label: 'Option 2',
        description: 'This is the description of the second option',
    },
    {
        id: 3,
        label: 'Option 3',
        description: 'This is the description of the second option',
    },
]

const FormTemplate = () => {
    const [formData, setFormData] = React.useState({})

    const handleInputChange = (key, value) => {
        setFormData(formData => ({ ...formData, [key]: value }))
    }

    return (
        <Template
            hashValue='forms'
            componentName='Form'
            overview={
                <Form
                    title='My form'
                    submitLabel='Save changes'
                    submitCallback={() => alert('Hi there!')}
                    secondaryActions={[
                        {
                            label: 'Remove item',
                            callback: () => {},
                            buttonVariant: ButtonVariants.DANGER_SECONDARY,
                            buttonIcon: IconTypes.BIN,
                        },
                        {
                            label: 'Restore defaults',
                            callback: () => {},
                            buttonIcon: IconTypes.RESTORE,
                        },
                    ]}
                    doubleActionsUI={Boolean}
                    submitProgress={Number}
                >
                    <FormSection
                        title='My small section'
                        description='This is the small section of the form'
                        size={FormSectionSizes.SMALL}
                    >
                        <FormItem
                            label='Text input'
                            placeholder='A standard text input...'
                            value={formData.text}
                            onChange={e =>
                                handleInputChange('text', e.target.value)
                            }
                        />
                        <FormItem
                            label='Password input'
                            type={FormItemTypes.PASSWORD}
                            placeholder='A standard password input...'
                            value={formData.password}
                            onChange={e =>
                                handleInputChange('password', e.target.value)
                            }
                        />
                        <FormItem
                            label='Tooltiped input'
                            placeholder='A tooltiped text input...'
                            tooltip='This is a useful advice on what to input here'
                            value={formData.tooltiped}
                            onChange={e =>
                                handleInputChange('tooltiped', e.target.value)
                            }
                        />
                        <FormItem
                            label='Textarea input'
                            type={FormItemTypes.TEXTAREA}
                            placeholder='A standard textarea input...'
                            value={formData.textarea}
                            onChange={e =>
                                handleInputChange('textarea', e.target.value)
                            }
                        />
                        <FormItem
                            label='Disabled text input'
                            placeholder='A disabled text input...'
                            value={formData.disabledText}
                            onChange={e =>
                                handleInputChange(
                                    'disabledText',
                                    e.target.value,
                                )
                            }
                            disabled
                        />
                        <FormItem
                            label='Multi-select'
                            type={FormItemTypes.MULTI_SELECT}
                            values={DEFAULT_VALUES}
                            value={formData.multiSelect}
                            onChange={e =>
                                handleInputChange('multiSelect', e.target.value)
                            }
                            size={FormItemSizes.SMALL}
                        />
                    </FormSection>
                    <FormSection
                        title='My medium section'
                        description='This is the medium section of the form'
                        size={FormSectionSizes.MEDIUM}
                    >
                        <FormItem
                            label='Select dropdown'
                            values={DEFAULT_VALUES}
                            type={FormItemTypes.SELECT}
                            value={formData.select}
                            onChange={e =>
                                handleInputChange('select', e.target.value)
                            }
                            size={FormItemSizes.SMALL}
                        />
                        <FormItem
                            label='Disabled select dropdown'
                            values={DEFAULT_VALUES}
                            type={FormItemTypes.SELECT}
                            value=''
                            onChange={e => {}}
                            placeholder='Select an option...'
                            size={FormItemSizes.SMALL}
                            disabled
                        />
                        <FormItem
                            label='Card select'
                            type={FormItemTypes.CARD_SELECT}
                            values={DEFAULT_VALUES}
                            value={formData.cardSelect}
                            onChange={e =>
                                handleInputChange('cardSelect', e.target.value)
                            }
                            size={FormItemSizes.BIG}
                        />
                        <FormItem
                            label='Select'
                            values={[
                                { label: 'Option 1', id: 1 },
                                { label: 'Option 2', id: 2 },
                            ]}
                            type={FormItemTypes.SELECT}
                            value={formData.smallSelect}
                            onChange={e =>
                                handleInputChange('smallSelect', e.target.value)
                            }
                            size={FormItemSizes.SMALL}
                        />
                        <FormItem
                            label='Multi-select'
                            type={FormItemTypes.MULTI_SELECT}
                            values={DEFAULT_VALUES}
                            value={formData.multiSelect}
                            onChange={e =>
                                handleInputChange('multiSelect', e.target.value)
                            }
                            size={FormItemSizes.SMALL}
                        />
                    </FormSection>
                    <FormSection
                        title='My big section'
                        description='This is a full-width section'
                        size={FormSectionSizes.BIG}
                    >
                        <FormItem
                            label='Small element'
                            value={formData.text}
                            onChange={e =>
                                handleInputChange('text', e.target.value)
                            }
                            size={FormItemSizes.SMALL}
                        />
                        <FormItem
                            label='Another small element'
                            value={formData.anotherText}
                            onChange={e =>
                                handleInputChange('anotherText', e.target.value)
                            }
                            size={FormItemSizes.SMALL}
                        />
                        <FormItem
                            label='Full width element'
                            type={FormItemTypes.TEXTAREA}
                            value={formData.textarea}
                            onChange={e =>
                                handleInputChange('textarea', e.target.value)
                            }
                        />
                    </FormSection>
                </Form>
            }
            example={`
<Form
    title={String}
    submitLabel={String}
    submitCallback={Function}
    secondaryActions={
        [
            {
                name: any,
                callback: Function,
                label: String
            },
        ]
    }
    doubleActionsUI={Boolean}
    submitProgress={Number}
>
    // FormItem components here...
</Form>`}
            properties={[
                {
                    name: 'title',
                    type: 'STRING',
                    description: 'Title to show at the top of the form',
                },
                {
                    name: 'submitLabel',
                    type: 'STRING',
                    description: 'Custom label for the Submit button',
                },
                {
                    name: 'submitCallback',
                    type: 'FUNCTION',
                    required: true,
                    description:
                        'Callback to execute when the form is succesfully submitted',
                },
                {
                    name: 'secondaryActions',
                    type: 'ARRAY',
                    description: 'Array of secondaryActions.action',
                },
                {
                    name: 'secondaryActions.action',
                    type: 'OBJECT',
                    description: `Object containing the properties of the secondary action`,
                },
                {
                    name: '→',
                    content: 'callback',
                    type: 'FUNCTION',
                    description: `Callback to execute when the action is clicked`,
                },
                {
                    name: '→',
                    content: 'label',
                    type: 'STRING',
                    description: `Label to be shown on the action button`,
                },
                {
                    name: '→',
                    content: 'buttonVariant',
                    type: 'ButtonVariants',
                    description: `import { ButtonVariants } from 'rds'`,
                },
                {
                    name: '→',
                    content: 'buttonIcon',
                    type: 'IconTypes',
                    description: `import { IconTypes } from 'rds'`,
                },
                {
                    name: 'doubleActionsUI',
                    type: 'BOOLEAN',
                    description:
                        'If true, the form will have the action buttons at the top too',
                },
                {
                    name: 'submitProgress',
                    type: 'NUMBER',
                    description:
                        'Externally controlled number which indicates the progress of the submission',
                },
            ]}
        />
    )
}

export default FormTemplate
