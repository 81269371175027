import React from 'react'

const Emoji = ({ title, type }) => {
    return (
        <span className='rds-emoji' role='img' aria-label={title} title={title}>
            {type}
        </span>
    )
}

export default Emoji
