import React from 'react'
import Template from '../Layout/Template'
import { Logo, Card } from 'rds'

const LogoTemplate = () => {
    return (
        <Template
            hashValue='logo'
            componentName='Logo'
            overview={
                <Card>
                    <Logo />
                </Card>
            }
            example={`
<Logo />`}
            properties={[]}
        />
    )
}

export default LogoTemplate
